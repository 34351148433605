/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    calendar4: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.5 0a.5.5 0 01.5.5V1h8V.5a.5.5 0 011 0V1h1a2 2 0 012 2v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h1V.5a.5.5 0 01.5-.5M2 2a1 1 0 00-1 1v1h14V3a1 1 0 00-1-1zm13 3H1v9a1 1 0 001 1h12a1 1 0 001-1z"/>',
    },
});
